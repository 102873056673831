<template>
  <div>
    <!-- <div class="d-flex justify-content-start align-items-center my-10">
      Toplam {{ totalItems }} müşteri bulundu.
    </div> -->
    <div
      class="d-flex align-items-center justify-content-between search-list-area"
    >
      <div class="d-flex align-items-center search-input mb-4">
        <input placeholder="Ara.." v-model="search" class="form-control mr-2" />
        <select v-model="list_length" class="form-control ml-2">
          <option value="5" selected>Seçiniz</option>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
      </div>
      <div>
        <div>
          <b-button variant ="outline-success" class="mr-4" :to="{ name: 'kiraci-ekle' }">Ödeme Ekle</b-button>
          <b-button variant="outline-primary"   v-b-toggle.sidebar-right>Filtrele</b-button>
        </div>

      </div>
    </div>

    <div class="custom-list">
      <List :header="header" :data="items" :pagination="pagination" :loading="loading" @updatePage="updatePage"
              moduleName="Sistem Kullanıcı Listesi" :moduleCreateEvent="true" :moduleSearch="search"
              @selectedItemIdsChanged="selectedItemIdsChanged" @openCreateModal="openInsertUpdate('')">

              <template v-slot:item.email="{ item }">
                  <a :href="`mailto:${item.email}`">{{ item.email }}</a>
              </template>
              <template v-slot:item.isActive="{ item }">
                  <div v-html="$customFunctions.getIsActiveBadge(item.isActive)"></div>
              </template>
              <template v-slot:item.star="{ item }">
                  <div v-html="$customFunctions.getIsStar(item.star)"></div>
              </template>
              <template v-slot:item.IsActive="{ item }">
                  <b-badge variant="success" v-if="item.IsActive === true" >Ödendi</b-badge>
                  <b-badge variant="danger" v-else>Ödenmedi</b-badge>

              </template>
              <template v-slot:item.aidattutari="{item}">
                  <p class="text-dark" v-html="formatDate(item.aidattutari)"></p>
              </template>
              <template v-slot:item.sonodeme="{item}">
                  <b-badge  v-b-popover.hover.top="'Son Ödeme Tarihi'" style="cursor: pointer;" pill variant="warning" class="p-3"><i class="fas fa-exclamation fa-sm mr-4" style="color: red;"></i> {{ item.sonodeme }}</b-badge>
              </template>
              <template v-slot:item.action="{ item }" class="p-0">
                  <b-dropdown size="sm" no-caret dropleft variant="muted" style="z-index: 999;">
                      <template v-slot:button-content>
                          <i class="ki ki-bold-more-ver text-dark"></i>
                      </template>
                      <b-dropdown-item @click="openDetail(item)">Detay</b-dropdown-item>
                      <b-dropdown-item @click="openInsertUpdate(item)">Düzenle</b-dropdown-item>
                      <b-dropdown-item v-if="item.IsDeleted === false " @click="openDeleteHandler(item)">Sil</b-dropdown-item>
                  </b-dropdown>
              </template>
              <template v-slot:item.pdf="{ item }" class="p-0">
                <b-button variant="light" ><i class="fa fa-file fa-sm"></i>Pdf Al</b-button>
              </template>
              
          </List>
          <InsertUpdateModal :title="selectedItem == ''
              ? 'Sistem Kullanıcı Kaydı Ekle'
              : 'Sistem Kullanıcını Düzenle'
              " :show="showInsertUpdate" @closeDetail="closeInsertUpdate()" @confirm="confirmModal()"
              :resetForm="resetForm">
              <InsertUpdateOperations :selectedItem="selectedItem" :confirmModal="confirmCreate"
                  @resetInsertUpdateForm="resetInsertUpdateForm" />
          </InsertUpdateModal>

          <DetailModal title="Ödeme Detayı" :show="showDetail"
              @openInsertUpdate="openInsertUpdate(selectedItem)" @closeDetail="closeDetail">
              <DetailView :selectedItem="selectedItem" />
          </DetailModal>

          <ImportModal :show="showImportModal" importApiEndpoint="user/import" @closeDetail="closeImportModal()"
              @updateList="getList" @downloadSampleFile="exportOperations(true)" />

          <DeleteOperation       :url="deleteOperationUrl"
                  :params="deleteIds"
                  @updateList="getList"
                  modalid="deleteCustomer"
          />
      <!-- Create and Actions Popups -->
    </div>
    <div class="notes-sidebar top-full">
        <b-sidebar id="sidebar-right" right>
          <div class="card dveb-gray-card aside-gray-card tab-card" style="overflow-y:auto">
            <div class="card-header">
              FİLTRELE
              
            </div>
            <b-tabs content-class="mt-3">
            <b-tab content-class="tab-content" title="Filtre" active>
                <div class="form-group">
                    <label for="sahip">Sahibi</label>
                    <select name="" id="sahip" class="form-control" >
                        <option value="" selected>Seçiniz</option>
                        <option value="">Şekerbank A.Ş</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="izle">İzlenecek</label>
                    <select name="" id="izle" class="form-control" >
                        <option value="" selected>Seçiniz</option>
                        <option value="">Evet</option>
                        <option value="">Hayır</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="durum">Durum</label>
                    <select name="" id="durum" class="form-control">
                        <option value="" selected>Seçiniz</option>
                        <option value="">1</option>
                        <option value="">2</option>
                        <option value="">3</option>
                    </select>
                    
                </div>
                <div class="form-group">
                    <label for="il">İl</label>
                    <select name="" id="il" class="form-control">
                        <option value="" selected>Seçiniz</option>
                        <option value="">1</option>
                        <option value="">2</option>
                        <option value="">3</option>
                    </select>
                    
                </div>
                <div class="form-group">
                    <label for="ilce">İlçe</label>
                    <select name="" id="ilce" class="form-control">
                        <option value="" selected>Seçiniz</option>
                        <option value="">1</option>
                        <option value="">2</option>
                        <option value="">3</option>
                    </select> 
                </div>
                <div class="form-group">
                    <label for="anahat">Anahtar Durumu</label>
                    <select name="" id="anahat" class="form-control">
                        <option value="" selected>Seçiniz</option>
                        <option value="">Var</option>
                        <option value="">Yok</option>
                        <option value="">3</option>
                    </select> 
                </div>
                <div class="form-group">
                    <label for="anahat" class="form-label">İşgalci Durumu</label>
                    <select name="" id="anahat" class="form-control">
                        <option value="" selected>Seçiniz</option>
                        <option value="">Var</option>
                        <option value="">Yok</option>
                        <option value="">3</option>
                    </select> 
                </div>
                <div class="form-group">
                    <label for="anahat">Ada</label>
                    <input type="text" class="form-control">
                </div>
                <div class="form-group">
                    <label for="anahat">Parsel</label>
                    <input type="text" class="form-control">
                </div>
                <div class="row">
                    <div class="col-md-6 col-12 mt-4">
                        <b-button variant="light" class="w-100" >
                            Temizle
                        </b-button>
                    </div>
                    <div class="col-md-6 col-12 mt-4">
                        <b-button variant="light" class="w-100">
                            Uygula
                        </b-button>
                    </div>
                    <div class="col-12 mt-4">
                        <b-button variant="light" class="w-100">
                            Bu Filtreyi Kaydet
                        </b-button>
                    </div>
                </div>
            </b-tab>
            <b-tab title="Hızlı Ara">
                <div
                class="card mt-4 dveb-card-white"
                style="border-radius: 0 !important"
              >
                <div>
                  <h4> Filtre Örnek </h4>
                  <hr>
                  <div class="row ml-auto">
                    <b-button variant="success" class="mr-4" >
                      Uygula
                    </b-button>
                    <b-button variant="danger" class="" @click="openDeleteHandler(key)">
                      Sil
                    </b-button>
                  </div>
                </div>
            </div>
            </b-tab>
          </b-tabs>
          </div>
        </b-sidebar>
    </div>
    <DeleteOperation
    />
    <div>

    <b-modal v-model="openFullTextModal" title="BootstrapVue">
      <p class="my-4">Hello from modal!</p>
    </b-modal>
  </div>
  </div>
</template>

<style lang="scss" scoped>
@media (max-width: 990px) {
  .search-list-area {
    flex-direction: column;
    .search-input {
      width: 100%;
      * {
        width: 100%;
      }
    }
  }
  .search-buttons {
    flex-direction: column;
  }
}

.badges-area {
  display: flex;
  gap: 1rem;
  p {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: $dveb-primary !important;
  }
  .badges {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    .badge {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      padding: 0 !important;

      &.green {
        background-color: #44b6ae;
      }
      &.yellow {
        background-color: #ffac00;
      }
      &.red {
        background-color: red;
      }
    }
  }
}
.tapu-text {
  span {
    background-color: $dveb-divider;
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
  }
}
</style>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment'

export default {
  name: "customerList",
  
  data() {
    return {
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      badgesEnum: ["green", "yellow", "red"],
      stepNames : ["Hazırlık","Satış","Devir"],
      header: [
              { text: '', value: 'id', checkboxField: true, size: '50px' },
              { text: 'İş Ortağı', value: 'isortagi' },
              { text: 'Mülk Sahibi', value: 'mulksahibi' },
              { text: 'Ödenecek Tutar', value: 'odenecektutar' },
              { text: 'Ödeyecek Kişi', value: 'odeyecekkisi' },
              { text: 'Ödeme Nedeni', value: 'odemenedeni' },
              { text: "Son Ödeme Tarihi", value: "sonodeme", size: "200px" },
              { text: 'Ödeme Durumu', value: 'IsActive', size: '100px' },
              { text: 'PDF Al', value: 'pdf', size: '100px' },
              { text: '', value: 'action', size: '60px' },
          ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      filterList:[],
      list_length:5,
      openFullTextModal:false
    };
  },
  components:{
    Moretext : () =>import("@/components/global/Moretext")
  },
  created() {
    this.search = this.$route.query.search
    this.search = this.search.toLowerCase()
    this.getList();

  },
  methods: {
    getList() {
          this.loading = true;

          this.items = [
              {
                  isortagi:"DveB",
                  mulksahibi:"Ahmet Berke Aydın",
                  odenecektutar : "2000₺",
                  odeyecekkisi : "Nisa Karabacak",
                  odemenedeni: " Tadilat ",
                  sonodeme: "25/08/2023",
                  IsActive : true,
                  pdf:"",

              },
              {
                  isortagi:"DveB",
                  mulksahibi:"Ahmet Berke Aydın",
                  odenecektutar : "2000₺",
                  odeyecekkisi : "Nisa Karabacak",
                  odemenedeni: " Tadilat ",
                  sonodeme: "25/08/2023",
                  IsActive : false,
                  pdf:"",
              },
              {
                  isortagi:"DveB",
                  mulksahibi:"Ahmet Berke Aydın",
                  odenecektutar : "2000₺",
                  odeyecekkisi : "Nisa Karabacak",
                  odemenedeni: " Tadilat ",
                  sonodeme: "25/08/2023",
                  IsActive : true,
                  pdf:"",
              },
          ];
          this.totalItems = 0;
          this.loading = false;
          this.pagination = {
              current_page: 1,
              total_pages: 3,
              total_items: 30,
            };



      
    },
    updatePage(value) {
          this.meta = value;

          window.scrollTo({
              top: 0,
              behavior: 'smooth',
          });
      },
      confirmModal() {
          this.confirmCreate = true;
          setTimeout(() => {
              this.confirmCreate = false;
          }, 200);
      },
      resetInsertUpdateForm(closeInsertUpdate = false) {
          if (closeInsertUpdate) {
              this.closeInsertUpdate();
              this.getList();
          }

          this.resetForm = true;
          setTimeout(() => {
              this.resetForm = false;
          }, 200);
      },
      formatDate(date){
          return moment(date).format('LL')
      },
      closeInsertUpdate() {
          setTimeout(() => {
              this.selectedItem = '';
          }, 100);
          this.showInsertUpdate = false;
      },
      openInsertUpdate(item) {
          this.selectedItem = item;
          this.showInsertUpdate = true;
      },
      closeDetail() {
          this.showDetail = false;
      },
      openDetail(item) {
          this.selectedItem = item;
          this.showDetail = true;
      },
      closeImportModal() {
          this.showImportModal = false;
      },
      openImportModal() {
          this.showImportModal = true;
      },
      openDeleteHandler(item) {
          this.deleteOperationUrl = `musteriler_delete`;
          this.deleteIds = {token:this.myUser,id:item._id.$oid};
          this.$bvModal.show('modal-deleteOperationdeleteCustomer');
      },
      selectedItemIdsChanged(payload) {
          this.listSelectedItemIds = payload;
      },
      actions(payload) {
          if (
              this.listSelectedItemIds === [] ||
              this.listSelectedItemIds.length == 0
          ) {
              this.$generateNotification(
                  this,
                  'warning',
                  'İşlem yapılacak seçili kayıt bulunamadı!',
                  'İşleme devam edilemiyor.'
              );
          } else {
              if (payload == 'allRemove') {
                  this.deleteOperationUrl = `user`;
                  this.deleteIds = this.listSelectedItemIds;
                  this.$bvModal.show('modal-deleteOperation');
              } else if (payload == 'allInActive' || payload == 'allActive') {
                  this.loading = true;
                  this.$dbFunctions
                      .update(`user/update-status`, {
                          ids: this.listSelectedItemIds,
                          isActive: payload == 'allActive',
                      })
                      .then((res) => {
                          this.$dbFunctions.setResponse(this, res);
                          this.getList();
                      })
                      .catch((err) => {
                          this.$dbFunctions.setResponse(this, err);
                      })
                      .finally(() => {
                          this.loading = false;
                      });
              } else if (payload == 'allExport') {
                  this.exportOperations(false);
              }
          }
      },
      exportOperations(onlyHeaders) {
          this.loading = true;
          let payload = {
              Ids: this.listSelectedItemIds,
              onlyHeaders: onlyHeaders,
          };

          this.$dbFunctions
              .insertOrUpdate(`user/export`, 'export', payload)
              .then((res) => {
                  // this.$customFunctions.downloadFile(res.data);

                  this.$generateNotification(
                      this,
                      'success',
                      `Size özel dosyanız oluşturuldu. <a href="${res.data}" target="_blank" class="text-dark-50 text-hover-dark">Burayı tıklayarak</a> dosyanızı indirebilirsiniz.`,
                      'Dosyanız hazır!'
                  );
              })
              .catch((err) => {
                  this.$dbFunctions.setResponse(this, err);
              })
              .finally(() => {
                  this.loading = false;
              });
      },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function () {
      this.getList();
    },
    list_length:function(){
      this.getList()
    },
    $route:function(){
      this.search = this.$route.query.search
    this.search = this.search.toLowerCase()
    }
    

  },
 computed:{
  ...mapGetters(['myUser'])
 },
 components: {
      InsertUpdateOperations: () =>
          import('@/components/systemUser/modals/InsertUpdateOperations'),
      DetailView: () => import('@/components/customers/modals/DetailModal'),
  },
};
</script>
